import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './ContactRequest.module.scss';

const cx = classNames.bind(styles);

const ContactRequest = ({
  content,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  plan,
}) => (
  <>
    <Button
      className={cx('cta')}
      intent={plan.intent === 'highlight' ? 'white' : 'primary'}
      onClick={handleModalOpen}
    >
      {plan.cta}
    </Button>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('container', 'large')}>
        <Button
          aria-label={content['Subscription/Plans/ContactRequest/aria-close']}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <iframe
          className={cx('iframe')}
          scrolling="no"
          src={`${plan.form}?embeded=1`}
          style={{ height: '1150px', width: '100%' }}
          title="Web Forms"
        />
      </div>
    </Modal>
  </>
);

ContactRequest.propTypes = {
  content: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
};

export default ContactRequest;
