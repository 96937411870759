import { useCallback, useState } from 'react';

const useModal = (initialVisibility = false) => {
  const [isModalOpen, setVisibility] = useState(initialVisibility);
  const handleModalClose = useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setVisibility(false);
  }, []);
  const handleModalOpen = useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setVisibility(true);
  }, []);
  return {
    isModalOpen,
    handleModalOpen,
    handleModalClose,
  };
};

export default useModal;
