import React, { useCallback, useEffect, useState } from 'react';

import Plan from './Plan';

const SCREEN_SIZE_LIMIT = 800;

const PlanContainer = (props) => {
  // The isBrowser condition is needed to handle window object during build time with Gatsby
  const isBrowser = typeof window !== 'undefined';
  const [isFeatureOpen, setIsFeatureOpen] = useState(false);
  const [isFeatureToggleDisplayed, setIsFeatureToggleDisplayed] = useState(
    isBrowser ? window.innerWidth < SCREEN_SIZE_LIMIT : false,
  );
  const [isFeatureDisplayed, setIsFeatureDisplayed] = useState(
    isBrowser
      ? window.innerWidth > SCREEN_SIZE_LIMIT ||
          (window.innerWidth < SCREEN_SIZE_LIMIT && isFeatureOpen)
      : false,
  );
  const handleFeatureToggle = useCallback(() => {
    setIsFeatureOpen(!isFeatureOpen);
    setIsFeatureDisplayed(
      window.innerWidth > SCREEN_SIZE_LIMIT ||
        (window.innerWidth < SCREEN_SIZE_LIMIT && !isFeatureOpen),
    );
  }, [isFeatureOpen, setIsFeatureDisplayed]);
  const handleResize = useCallback(() => {
    setIsFeatureDisplayed(
      window.innerWidth > SCREEN_SIZE_LIMIT ||
        (window.innerWidth < SCREEN_SIZE_LIMIT && isFeatureOpen),
    );
    setIsFeatureToggleDisplayed(window.innerWidth < SCREEN_SIZE_LIMIT);
  }, [isFeatureOpen, SCREEN_SIZE_LIMIT]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
  return (
    <Plan
      handleFeatureToggle={handleFeatureToggle}
      isBrowser={isBrowser}
      isFeatureDisplayed={isFeatureDisplayed}
      isFeatureToggleDisplayed={isFeatureToggleDisplayed}
      {...props}
    />
  );
};

export default PlanContainer;
