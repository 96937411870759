import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import PilotImage from 'assets/images/pricing/pilot.svg';
import BurgundyImage from 'assets/images/pricing/burgundy.png';
import CIOImage from 'assets/images/pricing/cio.jpeg';
import EduserviceImage from 'assets/images/pricing/eduservice.jpeg';
import ENVAImage from 'assets/images/pricing/enva.jpeg';
import INPImage from 'assets/images/pricing/inp-toulouse.png';
import Lyon3Image from 'assets/images/pricing/lyon-3.png';
import RegionIDFImage from 'assets/images/pricing/region-idf.png';
import ClermontImage from 'assets/images/pricing/universite-clermont.jpeg';
import LaRochelleImage from 'assets/images/pricing/universite-la-rochelle.png';
import LausanneImage from 'assets/images/pricing/universite-lausanne.png';
import MontpellierImage from 'assets/images/pricing/universite-montpellier.png';
import ToulouseImage from 'assets/images/pricing/universite-toulouse.png';
import Details from 'components/Pricing/Details';
import FAQ from 'components/Pricing/FAQ';
import Layout from 'components/Layout';
import Plan from 'components/Pricing/Plan';
import Testimony from 'components/Shared/Testimony';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './Pricing.module.scss';

const cx = classNames.bind(styles);

const PricingPage = ({
  handlePlanToggle,
  handleTargetSwitch,
  isPlanOpen,
  isTargetEducation,
  pageContext: { lang, layout, content, urls },
}) => (
  <Layout lang={lang} t={layout} urls={urls}>
    <div className={cx('background-light')}>
      <div className={cx('container', 'first-container')}>
        <p className={cx('header')}>{content.header.toUpperCase()}</p>
        <h1 className={cx('title')}>{content.title}</h1>
        <p className={cx('subtitle')}>{content.subtitle}</p>
        <div className={cx('switches')}>
          <button
            className={cx('switch', { selected: isTargetEducation })}
            onClick={handleTargetSwitch}
            type="button"
          >
            {content.education}
          </button>
          <button
            className={cx('switch', { selected: !isTargetEducation })}
            onClick={handleTargetSwitch}
            type="button"
          >
            {content.business}
          </button>
        </div>
        <section>
          <div className={cx('plans')}>
            {Object.values(content.plans).map((plan) => (
              <Plan
                content={content}
                plan={isTargetEducation ? plan.education : plan.business}
              />
            ))}
          </div>
          {isPlanOpen && (
            <>
              <Details
                plans={Object.values(content.plans).map((plan) =>
                  isTargetEducation ? plan.education : plan.business,
                )}
                content={content.details}
              />
              <div className={cx('plans')}>
                {Object.values(content.plans).map((plan) => (
                  <Plan
                    content={content}
                    plan={isTargetEducation ? plan.education : plan.business}
                    isResume
                  />
                ))}
              </div>
            </>
          )}
          <Button
            className={cx('plan-toggle')}
            onClick={handlePlanToggle}
            size="large"
          >
            <span>{isPlanOpen ? content.planClose : content.planOpen}</span>
            <Icon
              className={cx('chevron')}
              name={isPlanOpen ? 'chevron-up' : 'chevron-down'}
            />
          </Button>
        </section>
        <section className={cx('pilot')}>
          <img alt="" className={cx('pilot-image')} src={PilotImage} />
          <div className={cx('pilot-text-container')}>
            <h2 className={cx('pilot-title')}>{content.pilotSection.title}</h2>
            <p className={cx('pilot-subtitle')}>
              {isTargetEducation
                ? content.pilotSection.subtitleEducation
                : content.pilotSection.subtitleBusiness}
            </p>
            <Button
              as="a"
              className={cx('pilot-cta')}
              href={content.pilotSection.link}
              intent="primary"
              size="large"
            >
              {content.pilotSection.cta}
            </Button>
          </div>
        </section>
      </div>
    </div>
    <div className={cx('background-dark')}>
      <div className={cx('container', 'second-container')}>
        <section className={cx('partners')}>
          {isTargetEducation && (
            <div className={cx('testimonies')}>
              <Testimony content={content.testimony1} />
              <Testimony content={content.testimony2} />
            </div>
          )}
          <h2 className={cx('partners-title')}>
            {content.partnerSection.title}
          </h2>
          <p className={cx('partners-subtitle')}>
            {isTargetEducation
              ? content.partnerSection.subtitleEducation
              : content.partnerSection.subtitleBusiness}
          </p>
          {isTargetEducation && (
            <div className={cx('partner-cards')}>
              {[
                ToulouseImage,
                Lyon3Image,
                LaRochelleImage,
                MontpellierImage,
                LausanneImage,
                ClermontImage,
                ENVAImage,
                INPImage,
                RegionIDFImage,
                EduserviceImage,
                BurgundyImage,
                CIOImage,
              ].map((image) => (
                <div className={cx('partner-card')}>
                  <img
                    alt=""
                    className={cx('partner-card-image')}
                    src={image}
                  />
                </div>
              ))}
            </div>
          )}
          <Button
            as="a"
            className={cx('partner-cta')}
            href={content.partnerSection.link}
            intent="primary"
          >
            {content.partnerSection.cta}
          </Button>
        </section>
      </div>
    </div>
    <div className={cx('background-light')}>
      <div className={cx('container', 'third-container')}>
        <section className={cx('faq')}>
          <div className={cx('faq-left')}>
            <p className={cx('faq-header')}>
              {content.faqSection.header.toUpperCase()}
            </p>
            <h2 className={cx('faq-title')}>
              {content.faqSection.mainQuestion.question}
            </h2>
            <p className={cx('faq-subtitle')}>
              {content.faqSection.mainQuestion.answer}
            </p>
          </div>
          <div className={cx('faq-right')}>
            {Object.values(content.faqSection.secondaryQuestions).map(
              (question) => (
                <FAQ content={question} />
              ),
            )}
          </div>
        </section>
      </div>
    </div>
  </Layout>
);
PricingPage.propTypes = {
  handlePlanToggle: PropTypes.func.isRequired,
  handleTargetSwitch: PropTypes.func.isRequired,
  isPlanOpen: PropTypes.bool.isRequired,
  isTargetEducation: PropTypes.bool.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default PricingPage;
