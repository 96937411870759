import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import PlanCampus from 'assets/images/pricing/plan-campus.svg';
import PlanCompany from 'assets/images/pricing/plan-company.svg';
import PlanFree from 'assets/images/pricing/plan-free.svg';
import PlanStudent from 'assets/images/pricing/plan-student.svg';
import PlanTeacher from 'assets/images/pricing/plan-teacher.svg';
import Icon from 'uikit/Icon';

import ContactRequest from './ContactRequest';
import styles from './Plan.module.scss';

const cx = classNames.bind(styles);

const SCREEN_SIZE_LIMIT = 800;
const IMAGES = {
  planFree: PlanFree,
  planStudent: PlanStudent,
  planTeacher: PlanTeacher,
  planCampus: PlanCampus,
  planCompany: PlanCompany,
};

const Plan = ({
  className,
  content,
  handleFeatureToggle,
  isBrowser,
  isFeatureDisplayed,
  isFeatureToggleDisplayed,
  isResume,
  plan,
}) => (
  <div className={cx('container', className)}>
    <div
      className={cx('card', {
        highlight: plan.intent === 'highlight',
        'resume-card': isResume,
      })}
    >
      {!isResume && (
        <img alt="" className={cx('image')} src={`${IMAGES[plan.image]}`} />
      )}
      <p className={cx('card-title')}>{plan.title}</p>
      {!isResume && <p className={cx('card-subtitle')}>{plan.subtitle}</p>}
      <div className={cx('pricing')}>
        <div className={cx('price-per-month')}>
          <div className={cx('price')}>{plan.price}</div>
          <div className={cx('per-month')}>{plan.perMonth}</div>
        </div>
        {plan.timing && <div className={cx('timing')}>{plan.timing}</div>}
      </div>
      <ContactRequest content={content} plan={plan} />
    </div>
    {isResume ? null : (
      <>
        {!isFeatureToggleDisplayed ? (
          <p className={cx('feature-title')}>{plan.featureTitle}</p>
        ) : (
          <button
            className={cx('feature-toggle')}
            onClick={handleFeatureToggle}
            type="button"
          >
            <span className={cx('feature-toggle-title')}>
              {plan.featureTitle}
            </span>
            <Icon
              className={cx('feature-toggle-icon')}
              name={isFeatureDisplayed ? 'chevron-up' : 'chevron-down'}
            />
          </button>
        )}
        {isBrowser &&
          (window.innerWidth > SCREEN_SIZE_LIMIT ||
            (window.innerWidth < SCREEN_SIZE_LIMIT && isFeatureDisplayed)) && (
            <li className={cx('feature-list-check')}>
              {Object.values(plan.features).map((feature) => (
                <ul className={cx('paragraph')}>{feature}</ul>
              ))}
            </li>
          )}
      </>
    )}
  </div>
);

Plan.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  handleFeatureToggle: PropTypes.func.isRequired,
  isBrowser: PropTypes.bool.isRequired,
  isFeatureDisplayed: PropTypes.bool.isRequired,
  isFeatureToggleDisplayed: PropTypes.bool.isRequired,
  isResume: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
};

Plan.defaultProps = {
  className: undefined,
};

export default Plan;
