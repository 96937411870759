import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const Modal = ({ children, className, isOpen, onExit }) => (
  <ReactModal
    ariaHideApp={false}
    className={cx('modal')}
    closeTimeoutMS={0}
    isOpen={isOpen}
    onRequestClose={onExit}
    overlayClassName={cx(className, 'overlay')}
  >
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onExit: PropTypes.func,
};

Modal.defaultProps = {
  className: undefined,
  isOpen: false,
  onExit: undefined,
};

export default Modal;
