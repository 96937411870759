import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import PricingPage from './Pricing';

const PricingPageContainer = (props) => {
  const [isTargetEducation, setTarget] = useState(true);
  const handleTargetSwitch = useCallback(() => {
    setTarget(!isTargetEducation);
  }, [isTargetEducation, setTarget]);
  const [isPlanOpen, setIsPlanOpen] = useState(false);
  const handlePlanToggle = useCallback(() => {
    setIsPlanOpen(!isPlanOpen);
  }, [isPlanOpen, setIsPlanOpen]);
  return (
    <PricingPage
      handlePlanToggle={handlePlanToggle}
      handleTargetSwitch={handleTargetSwitch}
      isPlanOpen={isPlanOpen}
      isTargetEducation={isTargetEducation}
      {...props}
    />
  );
};

PricingPageContainer.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default PricingPageContainer;
