import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Details.module.scss';

const cx = classNames.bind(styles);

const Details = ({ className, content, plans }) => (
  <div className={cx('container', className)}>
    <div className={cx('headers', 'grid')}>
      {['', ...plans].map((plan) => (
        <div className={cx('header')}>{plan.title}</div>
      ))}
    </div>
    {Object.values(content).map((group) => (
      <div className={cx('group')}>
        <div className={cx('group-title')}>{group.title}</div>
        {Object.values(group.features).map((feature) => (
          <div className={cx('feature', 'grid')}>
            <div className={cx('feature-title')}>{feature.title}</div>
            {feature.plan1 === 'true' ? (
              <div className={cx('plan')}>
                <Icon name="check" />
              </div>
            ) : (
              <div />
            )}
            {feature.plan2 === 'true' ? (
              <div className={cx('plan')}>
                <Icon name="check" />
              </div>
            ) : (
              <div />
            )}
            {feature.plan3 === 'true' ? (
              <div className={cx('plan')}>
                <Icon name="check" />
              </div>
            ) : (
              <div />
            )}
          </div>
        ))}
      </div>
    ))}
  </div>
);

Details.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
};

Details.defaultProps = {
  className: undefined,
};

export default Details;
