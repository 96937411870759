import React from 'react';

import useModal from 'hooks/useModal';

import ContactRequest from './ContactRequest';

const ContactRequestContainer = (props) => {
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  return (
    <ContactRequest
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      {...props}
    />
  );
};

export default ContactRequestContainer;
